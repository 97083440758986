<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          height="350"
        />
      </v-col>

      <v-col class="mb-4">
        <h2 class="font-weight-bold mb-3">ОТКАЗАНО В ДОСТУПЕ</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AccessDeniedView',
}
</script>
